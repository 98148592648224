
import React, { useEffect, useState, useCallback } from 'react';
import ChartContainer from '../ChartContainer/ChartContainer'
import Home from '../Home/Home'
import NavBar from '../NavBar/NavBar'
import Mission from '../Mission/Mission'
import ContactUs from '../ContactUs/ContactUs'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import history from './history';
import * as firebase from './firebase';
// import { Auth0Provider } from "@auth0/auth0-react";
import Auth0ProviderWithHistory from "../../auth/auth0-provider-with-history";
import ProtectedRoute from '../../auth/protected-route';
import './Earningshedge.css'

const theme = createTheme({
    overrides: {
          // Style sheet name ⚛️
      MuiInputLabel: {
        // Name of the rule
        root: {
          // Some CSS
          color: '#D9D9D9',
        },
      },
      MuiInputBase: {
        input: {
          color: '#D9D9D9'
        }
      },
     
    },
    palette: {
      primary: {
        main: '#F0BB46'
      },
      secondary: {
        main: '#666666'
      },
      action: {
        disabledBackground: '#f2f2f2'
      }
    }
});

export default function Earningshedge(props) {
    const [orientChanged, setOrientChanged] = useState(null);
    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);

    useEffect(() => {
      if (window.screen.width <= 500) {
        document.getElementsByClassName('earnings-hedge-container')[0].style.height = window.innerHeight;
      }
       getDimensions()
    }, []);

    const getDimensions = () => {
        const w = window.screen.width; 
        const h = window.screen.height;

        if (h > w) {
            setHeight(h)
            setWidth(w)
        } else {
            setHeight(w)
            setWidth(h)
        }
        return
    }

    return (
        <Router history={ history }>
            {/* <Auth0Provider
            domain="earningshedge.us.auth0.com"
            clientId="W4kkdJk9lKEbU6o1eiHO3PPVqc24my9b"
            redirectUri={window.location.origin+'/chart'}
            > */}
            <Auth0ProviderWithHistory>
                    <MuiThemeProvider theme={theme}>
                        <div className="earnings-hedge-container">
                            {/* <DeviceOrientation style={{height: '100%'}} lockOrientation={'portrait'}>
                                <Orientation className="orientation-container" style={{height: '100%'}} orientation='portrait' alwaysRender={true}> */}
                                    <NavBar></NavBar>
                                        <Switch>
                                            <Route 
                                                path="/chart"
                                                children={
                                                    <ChartContainer 
                                                        dimensions={{width,height}}
                                                        firebase={firebase}>
                                                    </ChartContainer>
                                                }
                                            />
                                            <Route path="/mission" children={
                                              <Mission>
                                              </Mission>
                                            } />
                                            <Route path="/contact-us" children={
                                              <ContactUs>
                                              </ContactUs>
                                            } />
                                            <Route path="/" children={
                                                <Home>
                                                </Home>
                                            } />
                                            
                                        </Switch>
                                {/* </Orientation>
                            </DeviceOrientation> */}
                        </div>
                    </MuiThemeProvider>
            </Auth0ProviderWithHistory>
            {/* </Auth0Provider> */}
        </Router>

// children={
//     <ChartContainer 
//         dimensions={{width,height}}
//         firebase={firebase}>
//     </ChartContainer>


    )

}