import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@material-ui/core";
import './Login.css'

const Login = (props) => {
  const { loginWithRedirect, isAuthenticated, logout } = useAuth0();

  return (
    <div className={props.className}>
      {!isAuthenticated && <Button style={{fontSize: '15px', padding: '0px', color: 'white', fontFamily:'Roboto Regular', fontWeight:'bold'}} onClick={() => loginWithRedirect()}>Log In</Button>}
      {isAuthenticated &&  <Button style={{fontSize: '15px', padding: '0px', color: 'white', fontFamily:'Roboto Regular', fontWeight:'bold'}} onClick={() => logout({ returnTo: window.location.origin })}>Log Out</Button>}
    </div>
  )
};

export default Login;