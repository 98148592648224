import React, { useEffect, useState, useCallback } from 'react';
import Login from '../Login/Login';
import './NavBar.css'
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import {
    BrowserRouter as Router,
    Link,
    Route
} from 'react-router-dom'
import logo from '../../assets/EH_Logo Full Color_White Text.png'
import hamburger from '../../assets/hamburger.svg';
import orange_x from '../../assets/orange_x.svg';



export default function NavBar(props) {

    const [showHamburger, setShowHamburger] = useState(false)
    const [pathName, setPathName] = useState(window.location.pathname);

    return (
        <div className='nav-bar'>
            <Link onClick={() => setPathName('')} className='nav-link' to='/'>
                <img className='logo' src={logo}></img>
            </Link>
            <div className='nav-container'>
                {window.innerWidth > 500 && 
                    <>
                        <Link onClick={() => setPathName('/chart')} className='nav-link' to='/chart'>
                            <div className={pathName === '/chart' ? 'selected nav-bar-item': 'nav-bar-item'}>DATA</div>
                        </Link>
                        <Link onClick={() => setPathName('/mission')} className='nav-link' to='/mission'>
                            <div className={pathName === '/mission' ? 'selected nav-bar-item': 'nav-bar-item'}>MISSION</div>
                        </Link>
                        <Link onClick={() => setPathName('/contact-us')} className='nav-link contact-us' to='/contact-us'>
                            <div className={pathName === '/contact-us' ? 'selected nav-bar-item': 'nav-bar-item'}>CONTACT US</div>
                        </Link>
                        <Login onClick={() => setPathName('')} className='nav-link login'></Login>
                    </>
                }
                {window.innerWidth <= 500 &&
                    <>
                        <img onClick={() => setShowHamburger(!showHamburger)} src={hamburger}></img>
                        {showHamburger &&
                            <div className="hamburger">
                                <img onClick={() => setShowHamburger(!showHamburger)} className="orange-x" src={orange_x}></img>
                                <Link className='nav-link' to='/chart'>
                                    <div onClick={() => setShowHamburger(!showHamburger)} className='nav-bar-item'>DATA</div>
                                </Link>
                                <Link className='nav-link' to='/mission'>
                                    <div onClick={() => setShowHamburger(!showHamburger)} className='nav-bar-item'>MISSION</div>
                                </Link>
                                <Link className='nav-link contact-us' to='/contact-us'>
                                    <div onClick={() => setShowHamburger(!showHamburger)} className='nav-bar-item'>CONTACT US</div>
                                </Link>
                                <Login onClick={() => setShowHamburger(!showHamburger)} className='nav-link login'></Login>
                            </div>
                        }   
                    </>
                }

            </div>
            
        </div>
    )
}
