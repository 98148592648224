import React, { useEffect, useState, useCallback } from 'react';
import './ContactUs.css'
import double_dash from '../../assets/double_dash.svg';
import double_dash_blue from '../../assets/double_dash_blue.svg';
import rocket from '../../assets/rocket.svg';
import our_team from '../../assets/our_team.svg';
import e_headshot from '../../assets/e_headshot.svg';
import g_headshot from '../../assets/g_headshot.svg';
import orange_x from '../../assets/orange_x.svg';
import contact_us from '../../assets/contact_us.svg';

export default function ContactUs(props) {

    return (
        <>
            <div className="CTA CTA-contact-us">
                <div className="CTA-left">
                    <p className="CTA-header">Get in touch.</p>
                    <p className="CTA-body CTA-body-contact-us">
                        Questions? We're here to help!
                    </p>
                    <img className="contact-us-img" src={contact_us} ></img>
                    <p className="homepage-subheader">
                        EMAIL
                    </p>
                    <p className="email">
                        earningshedge@gmail.com
                    </p>
                </div>
            </div>
            <br/>
            <div className="homepage-body homepage-body-contact-us">
                <img src={double_dash} className="double-dash"></img>
                <div className="homepage-body-container">
                    <h1 className="homepage-header homepage-header-contact-us">
                        Educational resources and guides:
                    </h1>
                    <p>
                        <a target="_blank" href="https://www.investopedia.com/articles/optioninvestor/11/predict-earnings-with-options.asp">
                            How to Use Options to Make Earnings Predictions
                        </a>
                        <br/><br/>
                        <a target="_blank" href="https://www.investopedia.com/ask/answers/032515/what-options-implied-volatility-and-how-it-calculated.asp">
                            Implied Volatility
                        </a>
                        <br/><br/>
                        <a target="_blank" href="https://www.cruxinvestor.com/articles/institutional-vs-retail-investors">
                            Institutional vs Retail Investors: Why Individuals Are at a Disadvantage
                        </a>
                        <br/><br/>
                    </p>
                </div>
            </div>
            
            <div style={{backgroundColor: '#0B3D84'}}className="footer">© 2022 Earnings Hedge. Made withㅤ❤️ㅤin Boston. All Rights Reserved.</div>
        </>
    )
}