import './App.css';
import Earningshedge from './components/EarningsHedge/Earningshedge';

function App() {
  return (
    <div>
      <Earningshedge></Earningshedge>
    </div>
  );
}

export default App;
