import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import './ChartContainer.css';
import history from '../EarningsHedge/history';
import firebase from '../EarningsHedge/firebase'
import TickerChart from '../TickerChart/TickerChart';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useLocation } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import SearchIcon from '@mui/icons-material/Search';
import HighestImpliedMove from '../HighestImpliedMove/HighestImpliedMove';
import loadingGif from '../../assets/loading.gif';
import { Autocomplete } from '@mui/material';
import { Select, MenuItem, InputLabel } from '@mui/material';

import { makeStyles } from '@material-ui/core/styles'
import ShowChartIcon from '@mui/icons-material/ShowChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const analytics = firebase.analytics()

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ChartContainer = (props) => {

  const devUrl = 'http://localhost:8888'
  const prodUrl = 'https://earningshedge.herokuapp.com'

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const query = useQuery();

  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    return [year, month, day].join('-');
  }
  const todayDate = new Date()
  const today = formatDate(todayDate)

  let oneWeekAgoDate = new Date()
  oneWeekAgoDate.setDate(oneWeekAgoDate.getDate() - 7)
  const oneWeekAgo = formatDate(oneWeekAgoDate)

  let oneMonthAgoDate = new Date()
  oneMonthAgoDate.setDate(oneMonthAgoDate.getDate() - 30)
  const oneMonthAgo = formatDate(oneMonthAgoDate)

  let threeMonthsAgoDate = new Date()
  threeMonthsAgoDate.setDate(threeMonthsAgoDate.getDate() - 90)
  const threeMonthsAgo = formatDate(threeMonthsAgoDate)

  let oneYearAgoDate = new Date()
  oneYearAgoDate.setDate(oneYearAgoDate.getDate() - 365)
  const oneYearAgo = formatDate(oneYearAgoDate)

  const [message, setMessage] = useState(null);
  const [data, setData] = useState([]);
  const [highestMovePenny, setHighestMovePenny] = useState([])
  const [highestMoveDollar, setHighestMoveDollar] = useState([])
  const [biggestCompanies, setBiggestCompanies] = useState([])
  const [earningsCalendar, setEarningsCalendar] = useState([])
  const [watchlist, setWatchlist] = useState([])
  // const [chartLoaded, setChartLoaded] = useState(false);
  const [ticker, setTicker] = useState(query.get("ticker") ? query.get("ticker").toUpperCase() : '');
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const [to, setTo] = useState(today);
  const [from, setFrom] = useState(oneWeekAgo);
  const [chartFrom, setChartFrom] = useState(null);
  const [chartTo, setChartTo] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [promptLogin, setPromptLogin] = useState(false);
  const [autocompleteTickers, setAutocompleteTickers] = useState([]);
  const [token, setToken] = useState(null);
  const [watermarkTicker, setWatermarkTicker] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [histoSeriesIsVisible, setHistoSeriesIsVisible] = useState(false);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState('1W');
  const [filterMenuVisible, setFilterMenuVisible] = useState(false);

  const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Accept': 'application/json',
  };

  useEffect(async () => {
    setTimeout(()=> {
      setPromptLogin(true)
    }, 1000)
    if (isAuthenticated) {
      try {
        const apiToken = await getAccessTokenSilently();
        setToken(apiToken)
        fetch(prodUrl + '/highestImpliedMove', {
          method: 'POST',
          headers: { ...headers, Authorization: `Bearer ${apiToken}`},
          body: JSON.stringify({ 
              date: today
          }),
        }).then(res => res.json())
        .then(json => {
            setHighestMovePenny(json[0].split(';'))
            setHighestMoveDollar(json[1].split(';'))
            setBiggestCompanies(json[2].split(';'))
            setEarningsCalendar(json[3].split(';').reverse())
            let t = '';
            if (ticker) {
              t = ticker
            } else if (query.get('ticker')) {
              t = query.get('ticker')
            } else {
              t = JSON.parse(json[2].split(';')[0]).ticker
            }
            analytics.logEvent(t);
            setTicker(t)
            setWatermarkTicker(t)
            // setQueryParams(ticker.toUpperCase(),)
            // analytics.logEvent(formatSymbolDisplayName(symbol,strike,exp,bear));
            fetch(prodUrl + '/impliedMove', {
              method: 'POST',
              headers: { ...headers, Authorization: `Bearer ${apiToken}`},
              body: JSON.stringify({ 
                  "ticker": t,
                  "to": to,
                  "from": from
              }),
            }).then(res => res.json())
            .then(async json => {
              setData(parseData(json))
              setDataLoaded(true)
              setQueryParams(t.toUpperCase())
              let wlist = null
              try {
                wlist = await fetch(prodUrl + '/watchlist', {
                  method: 'POST',
                  headers: { ...headers, Authorization: `Bearer ${apiToken}`},
                  body: JSON.stringify({ 
                      userId: user.sub,
                  }),
                }).then(res => res.text())
                .then(wlist => {
                  if (wlist) {
                    try {
                      if (wlist.split(',')[0] === '') {
                        setWatchlist([])
                      } else {
                        setWatchlist(JSON.parse(wlist))
                      }
                    } catch (e) {
                    }
                  }
                  setIsLoaded(true)
                  // if (window.navigator.platform.toLowerCase().includes('win')) {
                  //   document.querySelector('.highest-move-list').style.height = '106%';
                  //   document.querySelector('.highest-move-list').style.width = 'calc(99% + 17px)';
                  // }
                })
              }
              catch {
                setWatchlist([])
              }        
            })
          })
        
      } catch (e) {
        setMessage(e.message)
      }
    }
  }, [isAuthenticated, from]);

  useEffect(async () => {
    if (token && ticker) {
      
        fetch(prodUrl + '/tickers', {
          method: 'POST',
          headers: { ...headers, Authorization: `Bearer ${token}`},
          body: JSON.stringify({ 
              prefix: ticker
          }),
        }).then(res => {
          try{
            return res.text()
          } catch {
            return res.json()
          }
        })
        .then(text => {
          if (text) {
            const parsedAutocomplete = JSON.parse(text).map((t) => {return {name: t}})
            setAutocompleteTickers(parsedAutocomplete)
          }
        })
    }
  },[ticker,token])

  
  const setFilterMenuVisibleCallback = () => {
    setFilterMenuVisible(!filterMenuVisible);
  }

  const setQueryParams = (ticker) => {
    history.push({
      pathname: '/chart',
      search: '?ticker='+ticker
    })
  }

  const getAutoComplete = () => {

  }

  const updateWatchlist = async(t) => {
    try {
      const tempWatchlist = [...watchlist]
      if (!t) {
        if (tempWatchlist.includes(ticker)) {
          //dont update watchlist
          return
        }
        else {
          tempWatchlist.push(ticker)
        }

      } else {
        const index = tempWatchlist.indexOf(t);
        if (index > -1) {
          tempWatchlist.splice(index, 1);
        }
      }

      fetch(prodUrl + '/setWatchlist', {
        method: 'POST',
        headers: { ...headers, Authorization: `Bearer ${token}`},
        body: JSON.stringify({ 
            "userId": user.sub,
            "payload": tempWatchlist,
        }),
      }).then(res => res.text())
      .then(text => {
        setWatchlist(tempWatchlist)
        // analytics.logEvent(formatSymbolDisplayName(symbol,strike,exp,bear));
      });

    } catch(e) {
      setMessage(e.message)
    }
  }

  // core search
  const search = async (t) => {
    setDataLoaded(false)

    try {
      fetch(prodUrl + '/impliedMove', {
        method: 'POST',
        headers: { ...headers, Authorization: `Bearer ${token}`},
        body: JSON.stringify({ 
            "ticker": t.toUpperCase(),
            "to": to,
            "from": from
        }),
      }).then(res => res.json())
      .then(json => {
        setData(parseData(json))
        setQueryParams(t.toUpperCase())
        setTicker(t.toUpperCase())
        setWatermarkTicker(t.toUpperCase())
        setDataLoaded(true)
        analytics.logEvent(t);
        // analytics.logEvent(formatSymbolDisplayName(symbol,strike,exp,bear));
      });
    } catch(e) {
      setMessage(e.message)
    }
  }

  const handleTickerChange = event => {
    if (event.target.value) {
      const tempTicker = ticker ? ticker : '';
      if (tempTicker.toString() !== event.target.value.toUpperCase()) {
        setTicker(event.target.value.toUpperCase())
        setAutocompleteValue(event.target.value.toUpperCase());
        setQueryParams(event.target.value.toUpperCase())
      }
    } else {
      setAutocompleteValue('');
      setTicker('');
    }
  }
  
  const keyPress = (e) => {
    if(e.keyCode == 13){
      setTicker(e.target.value.toUpperCase());
      setQueryParams(e.target.value.toUpperCase())
      setWatermarkTicker(e.target.value)
      search(e.target.value)
    }
  }

  const setToOneWeek = () => {
    // to be implemented
  }

  const setToOneMonth = () => {
    // to be implemented
  }

  const setToThreeMonths = () => {
    // to be implemented
  }

  const setToOneYear = () => {
    // to be implemented
  }

  const handleTimeFrameChange = (e) => {
    setSelectedTimeFrame(e.target.value)
    setDataLoaded(false)
    if (e.target.value === '1W') {
      setFrom(oneWeekAgo)
    }
    if (e.target.value === '1M') {
      setFrom(oneMonthAgo)
    }
    if (e.target.value === '3M') {
      setFrom(threeMonthsAgo)
    }
    if (e.target.value === '1Y') {
      setFrom(oneYearAgo)
    }
  }

  const stringToDate = (s) =>  {
    s = s.split(/[-: ]/);
    return new Date(s[0], s[1]-1, s[2], s[3], s[4], s[5]);
  }
  
  const parseData = (data) => {
    const parseTime = (t) => {
      if (t.length == 3) {
        return t.slice(0,1)+':'+t.slice(-2)+':00'
      } else {
        return t.slice(0,2)+':'+t.slice(-2)+':00'
      }
    }
   
    const d = data.map(data => {
      const ret = []
      if (data.Item) {
      // for (let i=0;i<ticks.length;i++) {
        const itemKeys = Object.keys(data.Item)
        for (let i=0;i<itemKeys.length - 2;i++) {
          if (itemKeys[i] !== 'date' && itemKeys[i] !== 'ticker') {
            ret.push({
              time: stringToDate(data.Item.date + ' ' + parseTime(itemKeys[i])).getTime() / 1000,
              value: Number(Number(String(Math.round(Number(data.Item[itemKeys[i]]) * 1000)/1000).substring(0,4)).toFixed(2)),
              color: '#74aadc'
            })
          }
        }
      }
      return ret
    }).flat(1)
    if (d.length > 0) {
      setChartFrom(d[0].time)
      setChartTo(d[d.length-1].time)
    }
    return d
  }

  const autocompleteMenuItem = (props) => {
    return <Paper className="autocomplete-item" elevation={8} {...props} />
  }

  const toggleSeries = () => {
    setHistoSeriesIsVisible(!histoSeriesIsVisible);
  }

  const useStyles = makeStyles({
    paper: {
      backgroundColor: '#3b3b63',
      color: '#3b3b63',
      fontFamily: 'Roboto Regular',
      fontWeight: 900
    }
  });
  const classes  = useStyles();

  return (
    <>
      {(isAuthenticated && !isLoaded && <div className="centered-content"><img src={loadingGif} alt="loading"/></div>)}
      {(!isAuthenticated && promptLogin && <p className="centered-content">Please Login</p>)}
      {(isAuthenticated && isLoaded &&
      <>
      <div className="page-container">
        <div className='criteria-bar'>
          <div className="search-container">
              <Autocomplete id='standard-basic' 
                  label='Ticker'
                  onInputChange={handleTickerChange}
                  onBlur={handleTickerChange}
                  onKeyDown={keyPress}
                  options={autocompleteTickers}
                  autoComplete={true}
                  autoHighlight={true}
                  renderInput={params=> (
                    <TextField {...params} label="TICKER" variant="outlined" 
                    />
                  )}
                  getOptionLabel={option => option.name}
                  style= {{ display: 'inline-flex' }}
                  classes ={{ paper: classes.paper }}
                  disableClearable={true}
              />
              <SearchIcon
                className='search-button'
                onClick={()=>search(ticker)}
              >
              </SearchIcon>
          </div>
            {/* <span className="chart-title">Implied Move (%)</span> */}
            <div className="criteria-content-sub-row">
              <div onClick={() => setFilterMenuVisible(!filterMenuVisible)} className='adjust-filter'>
                <p>VIEW FILTERS</p>
                <ArrowDropDownIcon></ArrowDropDownIcon>
              </div>

              <div className="time-frame-button-container">
                <Button
                  className='time-frame-button'
                  onClick={toggleSeries}
                >
                  <ShowChartIcon className={histoSeriesIsVisible ? "" : "selected"}/>
                  /
                  <BarChartIcon className={histoSeriesIsVisible ? "selected" : ""}/>
                </Button>
              <TextField
                labelId="time-frame"
                value={selectedTimeFrame}
                label=""
                select
                onChange={handleTimeFrameChange}
                className="time-frame-button"
                MenuProps={{ classes: { paper: classes.paper } }}

              >
                <MenuItem value={'1W'}>1W</MenuItem>
                <MenuItem value={'1M'}>1M</MenuItem>
                <MenuItem value={'3M'}>3M</MenuItem>
                
                {/* <MenuItem value={'1Y'}>1Y</MenuItem> */}
              </TextField>
            </div>
          </div>
          { window.innerWidth > 500 &&
          <HighestImpliedMove
                    className="highest-implied-move"
                    penny={highestMovePenny}
                    dollar={highestMoveDollar}
                    biggest={biggestCompanies}
                    earningsCalendar={earningsCalendar}
                    watchlist={watchlist}
                    watchlistCallback={updateWatchlist}
                    search={search}
                    visible={filterMenuVisible}
                    setVisible={setFilterMenuVisibleCallback}
          ></HighestImpliedMove>
          }

        </div>
        { window.innerWidth <= 500 &&
        <HighestImpliedMove
                  className="highest-implied-move"
                  penny={highestMovePenny}
                  dollar={highestMoveDollar}
                  biggest={biggestCompanies}
                  earningsCalendar={earningsCalendar}
                  watchlist={watchlist}
                  watchlistCallback={updateWatchlist}
                  search={search}
                  visible={filterMenuVisible}
                  setVisible={setFilterMenuVisibleCallback}
        ></HighestImpliedMove>
        }
        <div className="content-row">
          <div className="chart-title">
              <p className="chart-title-highlighted chart-title-text">{watermarkTicker}</p>  <p className="chart-title-text">&nbsp;IMPLIED MOVE (%)</p>
          </div>
          <div onClick={(e)=> e.preventDefault()}className='chart-container'>
          {(data.length > 0 && dataLoaded) ?
            <TickerChart
              ticker = {watermarkTicker}
              data = {data}
              to = {chartTo}
              from = {chartFrom}
              chartWidth = {props.dimensions.width}
              chartHeight = {props.dimensions.height}
              histoSeriesIsVisible = {histoSeriesIsVisible}
            ></TickerChart>
            :
            dataLoaded ?
            <p className="no-data">No data for {watermarkTicker.split(' ')[0]}...</p>
            :
            <div className="no-data"><img src={loadingGif} alt="loading"/></div>

          }
          </div>
        </div>
      </div>
      </>
    )}
    </>
  )

}

export default ChartContainer;
