import React, { useEffect, useState } from 'react';
import './HighestImpliedMove.css';
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import EventIcon from '@mui/icons-material/Event';
import CloseIcon from '@mui/icons-material/Close';

const HighestImpliedMove = (props) => {

    const [penny, setPenny] = useState(props.penny)
    const [dollar, setDollar] = useState(props.dollar)
    const [biggest, setBiggest] = useState(props.biggest)
    const [earningsCalendar, setEarningsCalendar] = useState(props.earningsCalendar)
    const [watchlist, setWatchlist] = useState(props.watchlist)
    const [feature, setFeature] = useState('')
    const [visible, setVisible] = useState(props.visible)
    const filterLabels = ['BIGGEST COMPANIES BY MARKET CAP',
    'UPCOMING EARNINGS',
    'HIGHEST VOLATILITY BY MARKET CAP > 1 BILLION',
    'HIGHEST VOLATILITY BY MARKET CAP < 1 BILLION',
    'MY WATCHLIST'
    ]
    const [currentFilterLabel, setCurrentFilterLabel] = useState("")

    const trimLeadingZero = (str) => {
        if (str[0] === '0') {
            return str.substring(1);
        } else return str;
    }


    useEffect(() => {
     
        if (props.penny[0] && props.dollar[0] && 
            !React.isValidElement(penny[0]) && !React.isValidElement(penny[0])) {
            setPenny(
                props.penny.map(x => 
                    <li><a onClick={()=> props.search(JSON.parse(x).ticker)}>{JSON.parse(x).ticker}</a><p className='percent'>{Number(JSON.parse(x).pct).toFixed(2)}%</p></li>
                )
            )
            setDollar(
                props.dollar.map(x => 
                    <li><a onClick={()=> props.search(JSON.parse(x).ticker)}>{JSON.parse(x).ticker}</a><p className='percent'>{Number(JSON.parse(x).pct).toFixed(2)}%</p></li>
                )
            )
            setBiggest(
                props.biggest.map(x => 
                    <li><a onClick={()=> props.search(JSON.parse(x).ticker)}>{JSON.parse(x).ticker}</a><p className='percent'>{Number(JSON.parse(x).pct).toFixed(2)}%</p></li>
                )
            )
            setEarningsCalendar(
                props.earningsCalendar.map(x => 
                    <li><a onClick={()=> props.search(JSON.parse(x).ticker)}>{JSON.parse(x).ticker}</a><p className='percent'>{trimLeadingZero(JSON.parse(x).date)}</p></li>
                )
            )
            // setWatchlist(
            //     props.watchlist.map(x => 
            //         <li><a onClick={()=> props.search(x)}>{x}</a></li>
            //     )
            // )
            // if (window.navigator.platform.toLowerCase().includes('win')) {
            //     document.querySelector('.highest-move-list').style.height = '106%';
            // }
            // calc(100% - 18px);
        }
    }, [props.penny, props.dollar, props.biggest, props.earningsCalendar]);

    useEffect(() => {
        setWatchlist(
            props.watchlist.map(x => 
                <li><a onClick={()=> props.search(x)}>{x}</a><p onClick={() => props.watchlistCallback(x)}className='remove-ticker'>-</p></li>
            )
        )
    },[props.watchlist])

    useEffect(() => {
        setVisible(props.visible)
    },[props.visible])

    const watchlistContent = () => {
        return (
            <>
                <div className="add-ticker" onClick={() =>  props.watchlistCallback()}>+</div>
                {watchlist}
            </>
        )
    }

    return (
        <>
        { visible &&
            <div className='highest-move-container'>
                <div className="tab-container">
                    <div                             
                        onClick={() => {
                            setFeature('Bi');
                            props.setVisible();
                            setCurrentFilterLabel(filterLabels[0]);}
                        } 
                        className="feature-tab-container">
                        {/* <Tooltip 
                            className="tooltip" 
                            title="Biggest Companies by Market Cap"
                            enterTouchDelay={0}
                        >
                            <InfoOutlinedIcon style={{ color: "white" }}></InfoOutlinedIcon>
                        </Tooltip> */}
                        <div 
                            className={`feature-tab ${feature === 'Bi' ? "selected" : ""}`}
                            >
                                <AccountBalanceIcon fontSize="large"></AccountBalanceIcon>
                        </div>
                        <p className="feature-tab-text">{filterLabels[0]}</p>
                    </div>
                    <div 
                        className="feature-tab-container"
                        onClick={() => {
                            setFeature('E');
                            props.setVisible();
                            setCurrentFilterLabel(filterLabels[1]);}
                        } 
                    >
                        {/* <Tooltip 
                            className="tooltip" 
                            title="Upcoming Earnings"
                            enterTouchDelay={0}
                        >
                            <InfoOutlinedIcon style={{ color: "white" }}></InfoOutlinedIcon>
                        </Tooltip> */}
                        <div 
                            className={`feature-tab ${feature === 'E' ? "selected" : ""}`}
                            >
                                <EventIcon fontSize="large"></EventIcon>
                        </div>
                        <p className="feature-tab-text">{filterLabels[1]}</p>
                    </div>
                    <div 
                        onClick={() => {
                            setFeature('B');
                            props.setVisible();
                            setCurrentFilterLabel(filterLabels[2]);}
                    }
                        className="feature-tab-container">
                        {/* <Tooltip 
                            className="tooltip" 
                            title="Highest volatility sorted by Market Cap > 1 Billion"
                            enterTouchDelay={0}
                        >
                            <InfoOutlinedIcon style={{ color: "white" }}></InfoOutlinedIcon>
                        </Tooltip> */}
                        <div  
                            className={`feature-tab ${feature === 'B' ? "selected" : ""}`}
                            >
                                <AttachMoneyOutlinedIcon fontSize="large"></AttachMoneyOutlinedIcon>
                        </div>
                        <p className="feature-tab-text">{filterLabels[2]}</p>
                    </div>
                    <div 
                        onClick={() => {
                            setFeature('M');
                            setVisible();
                            setCurrentFilterLabel(filterLabels[3]);}
                        } 
                        className="feature-tab-container">
                        {/* <Tooltip 
                            className="tooltip" 
                            title="Highest volatility sorted by Market Cap < 1 Billion"
                            enterTouchDelay={0}
                        >
                            <InfoOutlinedIcon style={{ color: "white" }}></InfoOutlinedIcon>
                        </Tooltip> */}
                        <div 
                            className={`feature-tab ${feature === 'M' ? "selected" : ""}`}
                            >
                                <AttachMoneyOutlinedIcon fontSize="small"></AttachMoneyOutlinedIcon>
                        </div> 
                        <p className="feature-tab-text">{filterLabels[3]}</p>
                    </div>
                    <div 
                        onClick={() => {
                            setFeature('W');
                            setCurrentFilterLabel(filterLabels[4]);
                            props.setVisible();}
                        } 
                        className="feature-tab-container">
                        {/* <Tooltip 
                            className="tooltip"
                            title="Watchlist"
                            enterTouchDelay={0}
                        >
                            <InfoOutlinedIcon style={{ color: "white" }}></InfoOutlinedIcon>
                        </Tooltip> */}
                        <div 
                            className={`feature-tab ${feature === 'W' ? "selected" : ""}`}
                            >
                                <StarOutlinedIcon></StarOutlinedIcon>
                        </div>
                        <p className="feature-tab-text">{filterLabels[4]}</p>
                    </div>
                </div> 
            </div>
        }
        {
            (!visible && 
                <>
                { currentFilterLabel && 
                    <div className="filter-label">
                        <p onClick={() => {
                            setFeature('');
                            setCurrentFilterLabel("");
                            }
                        }
                        className="filter-label-text">{currentFilterLabel} <CloseIcon></CloseIcon></p>

                    </div>
                }
                {
                    feature &&
                    <div className='highest-move-list'>
                        {(feature === 'B' && dollar)}
                        {(feature === 'M' && penny)}
                        {(feature === 'W' && watchlistContent())}
                        {(feature === 'Bi' && biggest)}
                        {(feature === 'E' && earningsCalendar)}
                    </div>
                }   
                </>
            )
        }
        </>
    )
}

export default HighestImpliedMove;
