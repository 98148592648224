// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDaUqbZ8s98JKwTgMaAjm4mKUtZvVAVaME",
  authDomain: "earningshedge.firebaseapp.com",
  projectId: "earningshedge",
  storageBucket: "earningshedge.appspot.com",
  messagingSenderId: "485864433683",
  appId: "1:485864433683:web:64cfa7a649d3919c5637e6",
  measurementId: "G-XJB899HFXB"
};

export default firebase.initializeApp(firebaseConfig);
