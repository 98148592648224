import React, { useEffect, useState, useCallback } from 'react';
// import Login from '../Login/Login'
import './Home.css'
import { useAuth0 } from "@auth0/auth0-react";
import {
    Link
} from 'react-router-dom'
import { Button } from "@material-ui/core";
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import sign_up from '../../assets/sign_up.svg';
import CTA_image from '../../assets/EH_Mockup 1.svg';
import double_dash from '../../assets/double_dash.svg';
import up_arrow from '../../assets/up_arrow.svg';

export default function Home(props) {

    const { loginWithRedirect, isAuthenticated, logout } = useAuth0();

    return (
        <>
        <div>
            <div className="CTA">
                <div className="CTA-left">
                <p className="CTA-header">Hedge smarter.</p>
                <p className="CTA-body">Log in to access the same powerful data that Wall Street traders use everyday.</p>
                <img onClick={() => loginWithRedirect()} className="CTA-button" src={sign_up}></img> 
            </div>
            <div className="CTA-right">
                <img className="CTA-image" src={CTA_image}></img>
            </div>
            </div>
            <br/>
            <div className="homepage-body">
                <img src={double_dash} className="double-dash"></img>
                <div className="homepage-body-container">
                    <img src={up_arrow} className="up-arrow"></img>
                    <h1 className="homepage-header">
                        It's all about the data.
                    </h1>
                    <h2 className="homepage-subheader">
                        BRINGING WALL STREET TO YOU.
                    </h2>
                    <p className="homepage-paragraph">
                        This data provides the implied price move associated with the underlying equity. 
                        <br></br><br></br>
                        In certain cases, market pricing may be such that an implied move cannot be calculated and no data will be available for certain tickers on certain days. 
                        <br></br><br></br>
                        This proprietary valuation model developed by Cboe Global Markets, Inc. uses a business day calendar with intraday time decay along with discrete dividend estimates, implied borrow rates, and proprietary arbitrage-free smoothed volatility surfaces.
                        <br></br><br></br>
                    </p>
                </div>
            </div>
            <div className="footer">© 2022 Earnings Hedge. Made withㅤ❤️ㅤin Boston. All Rights Reserved.</div>
        </div>
        </>
    )

}
