import React, { useEffect, useState } from 'react';
// import Chart from 'kaktana-react-lightweight-charts'
import { createChart, TickMarkType } from 'lightweight-charts';

export default function TickerChart(props) {

    const ref = React.useRef();
    const [chart, setChart] = useState(null)
    const [histoSeries, setHistoSeries] = useState(null)
    const [lineSeries, setLineSeries] = useState(null)
    const [histoSeriesIsVisible, setHistoSeriesIsVisible] = useState(false)
    const [ticker, setTicker] = useState(props.ticker)



    const darkTheme = {
        chart: {
            layout: {
                backgroundColor: 'rgb(43,43,67,1)',
                lineColor: '#74aadc',
                textColor: '#D9D9D9',
                //#4de1ff
            },
            // watermark: {
            //     visible: true,
            //     color: 'white',
            //     text: ticker,
            //     fontSize: window.screen.width > 600 ? 24 : 18,
            //     vertAlign: 'top'
            // },
            crosshair: {
                color: '#F0BB46',
            },
            grid: {
                vertLines: {
                    color: '#2B2B43',
                },
                horzLines: {
                    color: '#363C4E',
                },
            },
        },
        series: {
                topColor: '#74aadc',
                bottomColor: '#74aadc',
                lineColor: '#74aadc',
        },
    };

    useEffect(() => {
        setTicker(props.ticker)
        if (chart) {
            chart.applyOptions({
                watermark: {
                    visible: true,
                    color: 'white',
                    text: props.ticker,
                    fontSize: window.screen.width > 600 ? 24 : 18,
                    vertAlign: 'top'
                }
            });
        } 
    },[props.ticker])

    useEffect(() => {
        if (props.data.length != 0 && props.from && props.to && ref.current && !chart) {
            let hscale = 1
            let wscale = 1
            let chartWidth = getComputedStyle(document.getElementsByClassName('chart-container')[0]).width.slice(0,-2)
            let chartHeight = getComputedStyle(document.getElementsByClassName('chart-container')[0]).height.slice(0,-2)


            // let wscale
            // let hscale
            if (window.screen.width <= 500 || window.screen.height <= 600) {
                wscale = 1
                // wscale = 1 - window.screen.width / 4000
                //2100
                // wscale = .79
                hscale = .6
                // chartWidth = props.chartWidth
                chartHeight = props.chartHeight
            }
            // else {
            //     if (window.navigator.platform.toLowerCase().includes('win')) {
            //         if (window.screen.width * window.devicePixelRatio === 1680 && window.screen.height * window.devicePixelRatio === 1050) {
            //             wscale = .91
            //             hscale = .416
            //         }
            //         else if (window.screen.width * window.devicePixelRatio === 1920 && window.screen.height * window.devicePixelRatio === 1080) {
            //             wscale = 1.0
            //             hscale = .376
            //         }
            //         else if (window.screen.width * window.devicePixelRatio === 1600 && window.screen.height * window.devicePixelRatio === 900) {
            //             wscale = 1.08
            //             hscale = .374
            //         } else {
            //             wscale = 1.0
            //             hscale = .376
            //         }

                    

            //         // wscale = 1.8 / (window.screen.width/window.screen.height)
            //         // hscale = .646 / (window.screen.width/window.screen.height)
            //     } else {
            //         wscale = 1
            //         if (window.devicePixelRatio > 1) {
            //             hscale = 0.3855
            //         } else hscale = .43
            //         hscale = .43 - ((window.devicePixelRatio-1)*.0445)
            //         //.43
            //         // .0445
            //     }
            // }
            const c = createChart(ref.current, { 
                width: chartWidth * wscale,
                height: chartHeight * hscale
             });
            const histoSeries = c.addHistogramSeries({
                base: 0,
            });
            const lineSeries = c.addLineSeries({color: '#74aadc',})
            lineSeries.setData(props.data)
            histoSeries.setData(props.data)
            histoSeries.applyOptions(options)
            histoSeries.applyOptions(darkTheme.series);
            histoSeries.applyOptions({
                visible: false,
            });
            lineSeries.applyOptions({
                visible: true,
            });
            lineSeries.applyOptions(options)
            lineSeries.applyOptions(darkTheme.series);
            setChart(c) 
            setHistoSeries(histoSeries) 
            setLineSeries(lineSeries)
        }
 
    }, [ref,props.to,props.from,props.data]);

    useEffect(() => {

        const changeChartDims = () => {
            if (chart && getComputedStyle(document.getElementsByClassName('chart-container')[0]).width) {
                chart.applyOptions({
                    width: getComputedStyle(document.getElementsByClassName('chart-container')[0]).width.slice(0,-2),
                    height: getComputedStyle(document.getElementsByClassName('chart-container')[0]).height.slice(0,-2)
                });
            }
        }
        if (chart) {
            window.addEventListener('resize', ()=>changeChartDims());
        }
    },[chart])
  
    useEffect(() => {
        if (chart && chart.timeScale()) {
            chart.timeScale().fitContent();
            chart.timeScale().applyOptions({
                rightOffset: .5,
            });
            chart.applyOptions({
                timeScale: {
                    tickMarkFormatter: (time) => {
                        return timeConverter(time);
                    },
                },
            });
            chart.applyOptions(darkTheme.chart);
        }   
    },[chart])

    useEffect(() => {
        if (props.data && histoSeries) {
            histoSeries.setData(props.data)
            chart.timeScale().fitContent();
            chart.priceScale().applyOptions({autoScale : true})
        }
    },[props.data,histoSeries])

    useEffect(() => {
        if(histoSeries && lineSeries) {
            if (props.histoSeriesIsVisible) {
                histoSeries.applyOptions({
                    visible: true,
                });
                lineSeries.applyOptions({
                    visible: false
                })
            } else {
                histoSeries.applyOptions({
                    visible: false,
                });
                lineSeries.applyOptions({
                    visible: true
                })
            }
        }
    },[props.histoSeriesIsVisible])

    function timeConverter(UNIX_timestamp){
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        if (hour.toString().length === 1) {
            hour = '0' + hour;
        }
        var min = a.getMinutes();
        if (min.toString().length === 1) {
            min = '0' + min;
        }
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + hour + ':' + min ;
        return time;
    }

    const options = {
        crosshairMarkerBorderColor: '#F0BB46',
        crosshairMarkerBackgroundColor: '#F0BB46',
        lastValueVisible: false,

        alignLabels: true,
        timeScale: {
        //   rightOffset: 0,
        //   barSpacing: 10,
        //   fixLeftEdge: true,
        //   lockVisibleTimeRangeOnResize: true,
          rightBarStaysOnScroll: true,
          borderVisible: false,
          borderColor: "#fff000",
          visible: true,
          timeVisible: true,
          priceVisible: true,
          secondsVisible: true,
        //   tickMarkFormatter: (time, tickMarkType, locale) => {
        //     // const year = LightweightCharts.isBusinessDay(time) ? time.year : new Date(time * 1000).getUTCFullYear();
        //     return timeConverter(time);
        //     },
        },
        // localization: {
        //     priceFormatter: price =>{  
        //         console.log(price)  
        //         return String(Math.round(price * 100)/100).substring(0,3)+'%'
        //     }
        //     ,
        // },
        priceFormat: {
            type: 'custom',
            // minMove: 0.1,
            // precision: 0,
            formatter: (price) => {
                
                // if (price || price == 0) {
                //     // console.log('AAAA', price.toString().slice(0,3))
                //     if(price.toString().length < 4) {
                //         console.log('AAAA',price.toString())
                //         return `${price.toString()} %`
                //     } else {
                //         if (price < 0) {
                //             return '0%'
                //         }
                //         console.log('BBBB',price.toString().slice(0,4))
                //         return `${price.toString().substring(0,3)} %`
                //     }
                // }
                // if (typeof(price) == 'number' ) {
                //     console.log('gay',price.toString().substring(0,5))
                //     // return `${price.toString().substring(0,5)}%`
                //     return price
                // } else {
                //     console.log(typeof(price))
                //     return `${price} %`
                // }
                if (price >= 0) {
                    return `${price.toFixed(2)}%`
                } else return ''

            }

        },
        crosshair: {
            vertLine: {
                color: '#6A5ACD',
                width: 0.5,
                style: 1,
                visible: true,
                labelVisible: false,
            },
            horzLine: {
                color: '#6A5ACD',
                width: 0.5,
                style: 0,
                visible: true,
                labelVisible: true,
            },
            mode: 1,
        },
        scaleMargins: {
            bottom: .1,
            top: .1,
        }
    }

    return (
        <div style={{top:0,left:0,width:'100%',height:'100%'}} ref={ref} />
    )
}