import React, { useEffect, useState, useCallback } from 'react';
import './Mission.css'
import double_dash from '../../assets/double_dash.svg';
import double_dash_blue from '../../assets/double_dash_blue.svg';
import rocket from '../../assets/rocket.svg';
import our_team from '../../assets/our_team.svg';
import e_headshot from '../../assets/e_headshot.svg';
import g_headshot from '../../assets/g_headshot.svg';
import orange_x from '../../assets/orange_x.svg';

export default function Mission(props) {

    const [showElijahBio, setShowElijahBio] = useState(false)
    const [showGabeBio, setShowGabeBio] = useState(false)

    return (
        <>
        <div>
            <div className="CTA CTA-mission">
                <div className="CTA-left">
                <p className="CTA-header">We're on a mission.</p>
                <p className="CTA-body CTA-body-mission">
                    Earnings Hedge was created to empower retail investors and help them navigate the unknowns of major corporate events across their portfolio. 
                    <br></br><br></br>
                    By connecting users with the “Implied Earnings Move” data set, investors can access data on the implied future price action that is being forecast by the options market.
                </p>
            </div>
            </div>
            <br/>
            <div className="homepage-body">
                <img src={double_dash} className="double-dash"></img>
                <div className="homepage-body-container homepage-body-container-mission">
                    <img src={rocket} className="rocket"></img>
                    <h2 className="homepage-subheader">
                        PULLING BACK THE CURTAIN.
                    </h2>
                    <p className="homepage-paragraph homepage-paragraph-mission">
                    With the “Implied Earnings Move” data set, every investor can now leverage the same data used by so many elite Wall Street firms.
                    <br></br><br></br>
                    Our data pulls back the curtain on the exact price moves the options market is anticipating on any given day. 
                    <br></br><br></br>
                    This data is extremely powerful when making the decision to buy, sell or hold a particular position through significant market events such as earnings, FDA trial announcements, or monthly FOMC meetings.
                    </p>
                    <h2 className="homepage-subheader">
                        DATA FOR ALL.
                    </h2>
                    <p className="homepage-paragraph homepage-paragraph-mission">
                    At our core, the team at Earnings Hedge is here to help reshape the paradigm between retail and institutional data accessibility by delivering high quality data to everyday investors free of charge.
                    </p>
                </div>
            </div>
            <div className="our-team">
                <img src={double_dash_blue} className="double-dash"></img>
                <div className="our-team-body-container">
                    <img src={our_team} className="up-arrow"></img>
                    <h1 className="homepage-header">
                        Our team
                    </h1>
                    <h2 className="homepage-subheader">
                        THE PEOPLE POWERING THE DATA
                    </h2>
                    <div className="headshot-container">
                        {(!showElijahBio && !showGabeBio) &&
                            <>
                                <div className="headshot" onClick={() => setShowElijahBio(true)}>
                                    <img src={e_headshot}></img>
                                    <p className="headshot-header">Elijah Welch</p>
                                    <p className="headshot-subheader">CO-FOUNDER</p>
                                </div>
                                <div style={{width: '20%'}}></div>
                                <div className="headshot" onClick={() => setShowGabeBio(true)}>
                                    <img src={g_headshot}></img>
                                    <p className="headshot-header">Gabe Hollander</p>
                                    <p className="headshot-subheader">CO-FOUNDER</p>
                                </div>
                            </>
                        }
                        {showElijahBio &&
                            <>
                                <div className="headshot">
                                        <img src={e_headshot}></img>
                                        <p className="headshot-header">Elijah Welch</p>
                                        <p className="headshot-subheader">CO-FOUNDER</p>
                                </div>
                                <p className="bio-content">
                                    Elijah co-founded Earnings Hedge in 2021. His love for investing and trading began at the age of 15, but it was during his college years that he became deeply engaged with equity markets as he worked to invest and manage his own portfolio. 
                                    <br></br><br></br>
                                    Elijah recieved his BA in economics from New York University. He then launched his career at Bloomberg LP as an equity derivatives product specialist, helping equity buyside clientele to maximize the full suite of derivates-related functionality on the terminal.
                                    <br></br><br></br>
                                    Elijah’s other work has included roles as a programmatic trader for The Trade Desk, and an associate at Clayton Dubilier & Rice, a private equity firm managing over $35 billion of investments.
                                    <br></br><br></br>
                                    Today, Elijah dedicates his time to his investment firm, RW Investments, pursuing opportunistic equity derivatives strategies across both single name equities and large U.S. indexes.
                                </p>
                                <img className="exit-bio" src={orange_x} onClick={() => setShowElijahBio(false)}></img>
                            </>
                        }
                        {showGabeBio &&
                            <>
                                <div className="headshot">
                                        <img src={g_headshot}></img>
                                        <p className="headshot-header">Gabe Hollander</p>
                                        <p className="headshot-subheader">CO-FOUNDER</p>
                                </div>
                                <p className="bio-content">
                                    Gabe co-founded Earnings Hedge with Elijah in 2021. He has more than a decade of programming experience and has worked for a variety of tech companies to build and refine both web and mobile software and apps.
                                    <br></br><br></br>
                                    Currently, Gabe works as an engineer at MarkForged, an additive manufacturing company that designs, and develops 3D printers. Prior to MarkForged, he was a member of the digital engagement team at LogMeIn where he focused on chatbot infrastructure. He began his professional career as an engineer at Zoominfo. 
                                    <br></br><br></br>
                                    Gabe’s vast experience with end-to-end platform development has been integral in building the Earnings Hedge website, and will continue to power the development of new tools and functionality. Gabe has a B.S. in Computer Science from the University of Massachusetts Amherst.
                                </p>
                                <img className="exit-bio" src={orange_x} onClick={() => setShowGabeBio(false)}></img>
                            </>
                        }
                    </div>


                </div>
            </div>
            
            <div style={{backgroundColor: '#161626'}}className="footer">© 2022 Earnings Hedge. Made withㅤ❤️ㅤin Boston. All Rights Reserved.</div>
        </div>
        </>
    )
}